import { cva, type VariantProps } from "class-variance-authority";

import React from "react";
import { cn } from "~/lib/utils";

const textVariants = cva("text-base", {
  variants: {
    variant: {
      body: "text-md text-foreground",
      highlight: "text-md font-semibold text-primary-foreground",
      title: "text-3xl font-bold mb-2 text-foreground",
    },
  },
  defaultVariants: {
    variant: "body",
  },
});

export interface TextProps
  extends React.HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof textVariants> {}
const Text = React.forwardRef<HTMLSpanElement, TextProps>(
  ({ variant, className, ...props }, ref) => {
    return (
      <span className={cn(textVariants({ variant, className }))} {...props} />
    );
  }
);
Text.displayName = "Text";

export default Text;
